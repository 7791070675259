import { useEffect, useState } from "react";
import ArticleTeaser from "../../global/ArticleTeaser/index";
import styles from "./ArticleListTeaser.module.scss";
import ContentLibs from "../../../../cms_content/libs/ContentLibs";
import useSWR from "swr";
import ArticleApiService from "../../../services/ArticleApiService";
import PagePathUtility from "../../../../../libs/PagePathUtility";
import Button from "../../../../../components/Button/index";
import Link from "next/link";
import { getArticleUrlConfigKey } from "../../../services/LinkService";
import getConfig from "next/config";

export async function articleListFetcher(
  url,
  rubrik,
  themen = [],
  lawFirms,
  itemCount,
  leadFilterItems,
  articleIds
) {
  const { publicRuntimeConfig } = getConfig();
  const filter = {};

  if (
    articleIds !== null &&
    Array.isArray(articleIds) &&
    articleIds.length > 0
  ) {
    filter.articleIds = articleIds.map((articleId) => parseInt(articleId));
  } else {
    filter.rubrik =
      rubrik && parseInt(rubrik) > 0
        ? [parseInt(rubrik)]
        : Object.values(publicRuntimeConfig.articles.rubriken);
  }

  if (leadFilterItems && parseInt(leadFilterItems) > 0) {
    filter.leadFilterItems = parseInt(leadFilterItems);
    filter.leadFilterCategories = Object.values(
      publicRuntimeConfig.articles.rubriken
    );
  }

  if (themen) {
    let themenTags = themen.split(",");

    if (Array.isArray(themenTags) && themenTags.length > 0) {
      themenTags = themenTags.map((item) => {
        return parseInt(item);
      });
      filter.themenTags = themenTags;
    }
  }

  if (lawFirms) {
    let lawFirmsArray = lawFirms.split(",");

    if (Array.isArray(lawFirmsArray) && lawFirmsArray.length > 0) {
      lawFirmsArray = lawFirmsArray.map((item) => {
        return parseInt(item);
      });
      filter.lawFirms = lawFirmsArray;
    }
  }

  const articles = await ArticleApiService.fetchArticles(filter, itemCount);
  return articles.result.articles;
}

const ArticleListTeaser = ({
  rubrik,
  themen = [],
  lawFirms = [],
  itemCount,
  layout = 0,
  urlConfig,
  leadFilterItems,
  articleIds = null,
}) => {
  const { publicRuntimeConfig } = getConfig();
  const [isMobile, updateIsMobile] = useState(false);

  const layoutConfig = {
    0: {
      pattern: [
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
          layout: {
            name: "layoutImageTop",
          },
        },
      ],
      config: {
        repeatLast: true,
      },
    },
    100: {
      pattern: [
        {
          sizes: {
            large: 12,
            medium: 12,
            small: 12,
          },
          layout: {
            name: "layoutImageBehind",
          },
        },
        {
          sizes: {
            large: 6,
            medium: 6,
            small: 12,
          },
          layout: {
            name: "layoutImageTop",
          },
        },
        {
          sizes: {
            large: 6,
            medium: 6,
            small: 12,
          },
          layout: {
            name: "layoutImageTop",
          },
        },
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
          layout: {
            name: "layoutImageTop",
          },
        },
      ],
      config: {
        repeatLast: true,
      },
    },
    101: {
      pattern: [
        {
          sizes: {
            large: 12,
            medium: 12,
            small: 12,
          },
          layout: {
            name: "layoutImageRight",
          },
        },
        {
          sizes: {
            large: 6,
            medium: 6,
            small: 12,
          },
          layout: {
            name: "layoutImageTop",
          },
        },
        {
          sizes: {
            large: 6,
            medium: 6,
            small: 12,
          },
          layout: {
            name: "layoutImageTop",
          },
        },
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
          layout: {
            name: "layoutImageTop",
          },
        },
      ],
      config: {
        repeatLast: true,
      },
    },
  };

  useEffect(() => {
    updateIsMobile(window && window.innerWidth < 768);
  }, []);

  if (!rubrik) {
    return null;
  }

  const { data, error } = useSWR(
    [
      "/article",
      rubrik,
      themen.join(","),
      lawFirms.join(","),
      itemCount,
      leadFilterItems,
      articleIds,
    ],
    articleListFetcher,
    publicRuntimeConfig.globalSwrConfig
  );

  if (error) return <div>failed to load</div>;
  if (!data)
    return (
      <>
        <div>loading...</div>
      </>
    );

  const articleListLink = urlConfig
    ? PagePathUtility.getPathConfig(urlConfig, { page: 1 }, "list")
    : "";

  return (
    <div className={`${styles.teaserList}`}>
      <div className="grid-x grid-margin-x grid-margin-y">
        {data &&
          data.map((article, index) => {
            let itemLayoutConfig = ContentLibs.getItemSizeConfigFromColumnArray(
              layoutConfig[layout],
              index
            );

            const articleUrlConfig =
              urlConfig || getArticleUrlConfigKey(article);
            const articleDetailLink = PagePathUtility.getPathConfig(
              articleUrlConfig,
              { ...article },
              "detail"
            );
            const teaserImage = article.bild?.uid
              ? ArticleApiService.getImageUrl(article.bild.uid)
              : "";
            {
              /* On Mobile all Article have layoutImageTop */
            }
            return (
              <ArticleTeaser
                title={article.ueberschrift}
                altText={article.alttext || article.bild.allternative}
                teaserText={article.teasertext}
                linkHref={articleDetailLink}
                slug={article.slug}
                imageSrc={teaserImage}
                tag={article.dachzeile || ""}
                authorsHeadlinePrefix={article.autorHeadlinePrefix}
                authors={article.autoren}
                sizeLarge={itemLayoutConfig.sizes.large}
                sizeMedium={itemLayoutConfig.sizes.medium}
                sizeSmall={itemLayoutConfig.sizes.small}
                layout={
                  isMobile ? "layoutImageTop" : itemLayoutConfig.layout?.name
                }
                key={article.uid}
              />
            );
          })}
      </div>
      {articleListLink && (
        <div
          className={`grid-x grid-margin-x grid-margin-y ${styles.articleListButton}`}
        >
          <div className={"cell large-12 text-center"}>
            <Link href={articleListLink} passHref prefetch={false}>
              <Button layout="secondary" text={"Alle Artikel"} />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleListTeaser;
